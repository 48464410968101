import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { AuthApi } from '../api/auth.service';
import { AppService } from '../services/app.service';
import { Business } from '../models/business';
import { User } from '../models/user';
import { Store } from '../models/store';
import { RegisterLite } from '../models/register-lite';
import { Error } from '../interfaces/response';
import { NotifyService } from '../services/notify.service';
import { UserConfig } from '../services/user-config.service';
import { LoadingService } from '../services/loading.service';

@Injectable({ providedIn: 'root' })
export class InitializeResolver {
  constructor(
    private authApi: AuthApi,
    private app: AppService,
    private router: Router,
    private notify: NotifyService,
    private config: UserConfig,
    private loadingService: LoadingService
  ) {}

  resolve(): Observable<any> {
    this.loadingService.show();
    return this.authApi.data().pipe(
      map((response) => {
        if (response.ok) {
          // Obtener la tienda del usuario
          let store = response.data.stores.find((item) => item.id === this.config.currentLocalId);
          if (store === undefined) {
            store = response.data.stores[0];
          }

          // Obtener el registro seleccionado de la tienda
          let register = store.registers.find((item) => item.id === this.config.currentRegisterId);

          // Configurar los datos iniciales en la aplicación
          this.config.setInitialData(
            new Business(response.data.business),
            new User(response.data.user),
            new Store(store),
            // Si no hay registro seleccionado, se asigna null para sobreescribir el valor anterior, que podría ser un registro de otro negocio
            register ? new RegisterLite(register) : null
          );

          // Cargar los datos adicionales del negocio
          this.app.loadBusinessData();

          return true; // Indica que la resolución de datos fue exitosa
        } else if (response.error === Error.REGISTRATION_REQUIRED) {
          this.router.navigate(['/business-register']);
          return false;
        } else {
          this.notify.error(response);
          return false;
        }
      }),
      catchError((error) => {
        this.notify.error(error);
        this.router.navigate(['/error']);
        return of(false); // Retorna false en caso de error
      }),
      finalize(() => this.loadingService.hide())
    );
  }
}
