import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Observable, map, tap, of, catchError } from 'rxjs';
import { Response } from '../interfaces/response';
import { ImageResult, ImageDirectResult, ImageUploadResponse } from '../interfaces/image-result';
import { HttpClient, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  private readonly index = environment.apiBaseUrl + '/v1/images';
  private readonly indexSearch = environment.search;

  constructor(protected api: ApiService, private http: HttpClient) {}

  directUpload(): Observable<Response<ImageDirectResult>> {
    return this.api.post<Response<ImageDirectResult>>(ImageDirectResult, `${this.index}/direct_upload`, null);
  }

  // upload(uploadUrl: string, file: File): Observable<ImageUploadResponse> {
  //   const formData: FormData = new FormData();
  //   formData.append('file', file, file.name);
  //   return this.http.post<ImageUploadResponse>(uploadUrl, formData);
  // }

  upload(uploadUrl: string, file: File): Observable<ImageUploadResponse> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    // Use HttpClient with reportProgress enabled
    const req = this.http.post(uploadUrl, formData, {
      reportProgress: true,
      observe: 'events',
    });

    return req.pipe(
      map((event) => {
        if (event.type === HttpEventType.UploadProgress) {
          // Handle undefined total gracefully
          const total = event.total ?? 0; // Use 0 if total is undefined

          // Calculate upload progress (avoid division by zero)
          const progress = total === 0 ? 0 : Math.round((event.loaded * 100) / total);

          return { status: 'progress', progress: progress } as ImageUploadResponse;
        } else if (event instanceof HttpResponse) {
          const body = event.body as ImageUploadResponse;
          body.status = 'complete';
          return body;
        }

        return {} as ImageUploadResponse;
      })
    );
  }

  search(query: string): Observable<Response<ImageResult[]>> {
    return this.api.get<Response<ImageResult[]>>(ImageResult, `${this.indexSearch}/search?query=${query}`);
  }
}
