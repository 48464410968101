import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Issue } from '../models/issue';
import { Response } from '../interfaces/response';
import { IssueFilter } from '../filters/issue.filter';

@Injectable({
  providedIn: 'root',
})
export class IssueService {
  private readonly index = environment.apiBaseUrl + '/v1/issues';

  constructor(protected api: ApiService) {}

  get(id: string): Observable<Response<Issue | null>> {
    return this.api.get<Response<Issue | null>>(Issue, this.index + '/' + id);
  }

  all(p: IssueFilter): Observable<Response<Issue[]>> {
    return this.api.get<Response<Issue[]>>(Issue, this.index + (p?.getQuery() ?? ''));
  }

  create(issue: Issue): Observable<Response<Issue>> {
    return this.api.post<Response<Issue>>(Issue, this.index, issue);
  }

  update(issue: Issue): Observable<Response<Issue>> {
    return this.api.put<Response<Issue>>(Issue, this.index + '/' + issue.id, issue);
  }

  delete(issue: Issue): Observable<Response<Issue>> {
    return this.api.delete<Response<Issue>>(Issue, this.index + '/' + issue.id);
  }
}
