import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Advertisement } from '../models/advertisement';
import { Response } from '../interfaces/response';
import { AdvertisementFilter } from '../filters/advertisement.filter';

@Injectable({
  providedIn: 'root',
})
export class AdvertisementService {
  private readonly index = environment.apiBaseUrl + '/v1/advertisements';

  constructor(protected api: ApiService) {}

  get(id: string): Observable<Response<Advertisement | null>> {
    return this.api.get<Response<Advertisement | null>>(Advertisement, this.index + '/' + id);
  }

  all(p: AdvertisementFilter): Observable<Response<Advertisement[]>> {
    return this.api.get<Response<Advertisement[]>>(Advertisement, this.index + (p?.getQuery() ?? ''));
  }

  create(advertisement: Advertisement): Observable<Response<Advertisement>> {
    return this.api.post<Response<Advertisement>>(Advertisement, this.index, advertisement);
  }

  update(advertisement: Advertisement): Observable<Response<Advertisement>> {
    return this.api.put<Response<Advertisement>>(Advertisement, this.index + '/' + advertisement.id, advertisement);
  }

  delete(advertisement: Advertisement): Observable<Response<Advertisement>> {
    return this.api.delete<Response<Advertisement>>(Advertisement, this.index + '/' + advertisement.id);
  }

  sort(advertisementIds: string[]): Observable<Response<Advertisement>> {
    return this.api.post<Response<Advertisement>>(Advertisement, `${this.index}/sort`, advertisementIds);
  }
}
