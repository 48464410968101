import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Observable, from, map, tap } from 'rxjs';
import { Business } from '../models/business';
import { Response } from '../interfaces/response';
import { AppDatabase } from '../data/app-database';
import { liveQuery } from 'dexie';
import { BusinessEntity } from '../data/entities/business.entity';

@Injectable({
  providedIn: 'root',
})
export class BusinessService {
  private readonly index = environment.apiBaseUrl + '/v1/businesses';

  business$ = from(liveQuery(() => this.database.businesses.toArray())).pipe(
    map((items) => items.map((i) => new BusinessEntity(i).model()))
  );

  constructor(protected api: ApiService, private database: AppDatabase) {}

  get(id: string): Observable<Response<Business | null>> {
    return this.api.get<Response<Business | null>>(Business, this.index + '/' + id).pipe(
      tap((response) => {
        if (response.ok) {
          this.database.businesses.put(response.data!.entity());
        }
      })
    );
  }

  list(): Observable<Response<Business[]>> {
    return this.api.get<Response<Business[]>>(Business, this.index).pipe(
      tap((response) => {
        if (response.ok) {
          const entities = response.data.map((i) => i.entity());
          this.database.businesses.clear();
          this.database.businesses.bulkPut(entities);
        }
      })
    );
  }

  create(model: {
    name: string;
    id_number: string;
    category: string;
    time_zone: string;
    country_code: string;
    currency_code: string;
  }): Observable<Response<Business>> {
    return this.api.post<Response<Business>>(Business, this.index, model).pipe(
      tap((response) => {
        if (response.ok) {
          this.database.businesses.put(response.data.entity());
        }
      })
    );
  }

  update(model: Business): Observable<Response<Business>> {
    return this.api.put<Response<Business>>(Business, this.index + '/' + model.id, model).pipe(
      tap((response) => {
        if (response.ok) {
          this.database.businesses.put(response.data.entity());
        }
      })
    );
  }

  delete(model: Business): Observable<Response<Business>> {
    return this.api.delete<Response<Business>>(Business, this.index + '/' + model.id).pipe(
      tap((response) => {
        if (response.ok) {
          this.database.businesses.delete(model.id);
        }
      })
    );
  }
}
