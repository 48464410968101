import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Serie } from '../models/serie';
import { Response } from '../interfaces/response';
import { SerieFilter } from '../filters/serie.filter';

@Injectable({
  providedIn: 'root',
})
export class SerieService {
  private readonly index = environment.apiBaseUrl + '/v1/series';

  constructor(protected api: ApiService) {}

  get(id: string): Observable<Response<Serie | null>> {
    return this.api.get<Response<Serie | null>>(Serie, this.index + '/' + id);
  }

  all(p: SerieFilter | null = null): Observable<Response<Serie[]>> {
    return this.api.get<Response<Serie[]>>(Serie, this.index + (p ? p!.getQuery() : ''));
  }

  create(serie: Serie): Observable<Response<Serie>> {
    return this.api.post<Response<Serie>>(Serie, this.index, serie);
  }

  update(serie: Serie): Observable<Response<Serie>> {
    return this.api.put<Response<Serie>>(Serie, this.index + '/' + serie.id, serie);
  }

  delete(serie: Serie): Observable<Response<Serie>> {
    return this.api.delete<Response<Serie>>(Serie, this.index + '/' + serie.id);
  }
}
