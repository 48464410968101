import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Item } from '../models/item';
import { Response } from '../interfaces/response';
import { ItemFilter } from '../filters/item.filter';
import { ProductStockAdjusment } from '../models/product-stock-adjustment';
import { Product } from '../models/product';

@Injectable({
  providedIn: 'root',
})
export class ItemService {
  private readonly index = environment.apiBaseUrl + '/v1/items';

  constructor(protected api: ApiService) {}

  adjustment(body: ProductStockAdjusment): Observable<Response<Product>> {
    return this.api.post<Response<Product>>(Product, `${this.index}/${body.productId}/stock_adjustment`, body);
  }

  updatePackages(packages: any): Observable<Response<Item>> {
    return this.api.post<Response<Item>>(Item, `${this.index}/packages`, packages);
  }

  get(id: string): Observable<Response<Item | null>> {
    return this.api.get<Response<Item | null>>(Item, this.index + '/' + id);
  }

  all(p: ItemFilter | null = null): Observable<Response<Item[]>> {
    return this.api.get<Response<Item[]>>(Item, this.index + (p?.params || ''));
  }

  create(model: Item): Observable<Response<Item>> {
    return this.api.post<Response<Item>>(Item, this.index, model);
  }

  setIsActive(id: string, isActive: boolean): Observable<Response<Item>> {
    const body = { is_active: isActive };
    return this.api.put<Response<Item>>(Item, `${this.index}/${id}`, body);
  }

  update(model: Partial<Item>): Observable<Response<Item>> {
    const item = new Item(model);
    return this.api.put<Response<Item>>(Item, `${this.index}/${model.id}`, item);
  }

  delete(model: Item): Observable<Response<Item>> {
    return this.api.delete<Response<Item>>(Item, `${this.index}/${model.id}`);
  }
}
