import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Observable, map } from 'rxjs';
import { Product } from '../models/product';
import { Response, responseError, responseSuccess } from '../interfaces/response';
import { PackageTreeFilter } from '../filters/package-tree.filter';

@Injectable({
  providedIn: 'root',
})
export class PackageTreeService {
  private readonly index = environment.apiBaseUrl + '/v1/package_trees';

  constructor(protected api: ApiService) {}

  get(filter: PackageTreeFilter): Observable<Response<Product>> {
    return this.all(filter).pipe(
      map((i) => {
        if (i.ok) {
          return responseSuccess(i.data[0]);
        } else {
          return responseError(i.error);
        }
      })
    );
  }

  all(filter: PackageTreeFilter): Observable<Response<Product[]>> {
    return this.api.get<Response<Product[]>>(Product, this.index + filter.getQuery());
  }
}
