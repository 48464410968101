import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Tax } from '../models/tax';
import { Response } from '../interfaces/response';

@Injectable({
  providedIn: 'root',
})
export class TaxService {
  private readonly index = environment.apiBaseUrl + '/v1/taxes';

  constructor(protected api: ApiService) {}

  get(id: string): Observable<Response<Tax | null>> {
    return this.api.get<Response<Tax | null>>(Tax, this.index + '/' + id);
  }

  all(): Observable<Response<Tax[]>> {
    return this.api.get<Response<Tax[]>>(Tax, this.index);
  }

  create(tax: Tax): Observable<Response<Tax>> {
    return this.api.post<Response<Tax>>(Tax, this.index, tax);
  }

  update(tax: Tax): Observable<Response<Tax>> {
    return this.api.put<Response<Tax>>(Tax, this.index + '/' + tax.id, tax);
  }

  delete(tax: Tax): Observable<Response<Tax>> {
    return this.api.delete<Response<Tax>>(Tax, this.index + '/' + tax.id);
  }
}
