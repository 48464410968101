export interface NavItem {
  id?: string;
  name?: string;
  icon?: string | null;
  route?: string;
  active?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  isNew?: boolean;
  isAlpha?: boolean;
  children: NavItem[];
}

export let menuFooter: NavItem[] = [
  {
    // name: 'Asistente',
    icon: 'inner-shadow-bottom-filled',
    route: '/assistant',
    disabled: false,
    hidden: true,
    children: [
      {
        name: 'App',
        route: '/assistant/app',
        children: [],
      },
    ],
  },
  {
    name: 'Notify',
    icon: 'bell',
    route: '/notifications',
    hidden: true,
    children: [],
  },
  {
    name: 'Ayuda',
    id: 'help',
    icon: 'help', // help-square-rounded
    hidden: false,
    children: [],
  },
];

export let menu: NavItem[] = [
  {
    name: 'Pedidos',
    //icon: '/assets/icons/nav_orders.svg',
    icon: 'flame',
    route: '/orders',
    disabled: false,
    hidden: false,
    children: [
      {
        name: 'Pedidos',
        icon: null,
        route: '/orders/orders',
        disabled: false,
        hidden: false,
        isNew: false,
        children: [],
      },
      // {
      //   name: 'Usuarios',
      //   icon: null,
      //   route: '/customer/users',
      //   disabled: false,
      //   hidden: false,
      //   isNew: false,
      //   children: [],
      // },
      // {
      //   name: 'Destacados',
      //   icon: null,
      //   route: '/items/advertisements',
      //   disabled: false,
      //   hidden: false,
      //   isNew: false,
      //   isAlpha: true,
      //   children: [],
      // },
    ],
  },
  {
    name: 'Ventas',
    //icon: '/assets/icons/nav_orders.svg',
    // icon: 'shopping-cart',
    icon: 'shopping-bag',
    route: '/sales',
    disabled: false,
    hidden: true,
    children: [
      {
        name: 'Vender',
        icon: 'shopping-bag',
        route: '/sales/sell',
        disabled: false,
        hidden: false,
        children: [],
      },
      {
        id: 'sales-register',
        name: 'Abrir / Cerrar',
        icon: 'lock',
        route: '/sales/register',
        disabled: false,
        hidden: false,
        isNew: false,
        children: [],
      },
      {
        name: 'Historial',
        icon: 'history',
        route: '/sales/orders',
        disabled: false,
        hidden: false,
        children: [],
      },
      {
        id: 'sales-cash-management',
        name: 'Gestion de efectivo',
        icon: 'coins',
        route: '/sales/cash-management',
        disabled: true,
        hidden: false,
        children: [],
      },
      {
        id: 'sales-register-closure',
        name: 'Cierres de caja',
        icon: 'notes',
        route: '/sales/register-closures',
        disabled: true,
        hidden: false,
        children: [],
      },
    ],
  },
  {
    name: 'Productos',
    // icon: '/assets/icons/nav_products.svg',
    icon: 'cube',
    //icon: 'category',
    route: '/items',
    disabled: false,
    hidden: false,
    children: [
      // {
      //   name: 'Items',
      //   icon: null,
      //   route: '/items/items',
      //   disabled: false,
      //   hidden: false,
      //   children: [],
      // },
      {
        name: 'Productos',
        icon: 'cube',
        route: '/items/products',
        disabled: false,
        hidden: false,
        children: [],
      },
      {
        name: 'Historial / Precios',
        icon: 'history',
        route: '/items/price-histories',
        disabled: false,
        hidden: true,
        isNew: false,
        children: [],
      },
      {
        name: 'Imágenes',
        icon: null,
        route: '/items/images',
        disabled: false,
        hidden: true,
        isNew: false,
        children: [],
      },
      {
        name: 'Empaquetado',
        icon: null,
        route: '/items/packaging',
        disabled: false,
        hidden: true,
        children: [],
      },
      // {
      //   name: 'Descuentos',
      //   icon: null,
      //   route: '/items/discounts',
      //   disabled: false,
      //   hidden: false,
      //   children: [],
      // },
      {
        name: 'Categorías',
        icon: 'category',
        route: '/items/categories',
        disabled: false,
        hidden: false,
        children: [],
      },
      // {
      //   name: 'Destacados',
      //   icon: null,
      //   route: '/items/advertisements',
      //   disabled: false,
      //   hidden: false,
      //   isNew: false,
      //   children: [],
      // },
      {
        name: 'Marcas',
        icon: 'tag',
        route: '/items/brands',
        disabled: false,
        hidden: false,
        children: [],
      },
      // {
      //   name: 'Etiquetas',
      //   icon: 'hash',
      //   route: '/items/tags',
      //   disabled: false,
      //   hidden: false,
      //   isNew: false,
      //   children: [],
      // },
      // {
      //   name: 'Proveedores',
      //   icon: 'truck',
      //   route: '/items/suppliers',
      //   disabled: false,
      //   hidden: false,
      //   children: [],
      // },
      {
        name: 'Gift Cards',
        icon: null,
        route: '/items/gift-cards',
        disabled: false,
        hidden: true,
        children: [],
      },
    ],
  },
  {
    name: 'Clientes',
    // icon: '/assets/icons/nav_customer.svg',
    icon: 'user-hexagon',
    route: '/customer',
    disabled: true,
    hidden: true,
    children: [
      {
        name: 'Usuarios',
        icon: null,
        route: '/customer/users',
        disabled: false,
        hidden: true,
        isNew: false,
        children: [],
      },
      {
        name: 'Clientes',
        icon: 'user-hexagon',
        route: '/customer/customers',
        disabled: false,
        hidden: false,
        children: [],
      },
      {
        name: 'Grupos',
        icon: null,
        route: '/customer/groups',
        disabled: true,
        hidden: true,
        children: [],
      },
    ],
  },
  {
    name: 'Inventario',
    // icon: '/assets/icons/nav_inventory.svg',
    icon: 'package-export',
    route: '/inventory',
    disabled: true,
    hidden: true,
    children: [
      {
        name: 'Control de stock',
        icon: 'package-export',
        route: '/inventory/stock-control',
        disabled: false,
        hidden: false,
        children: [],
      },
      {
        name: 'Lotes', // old Explición
        icon: 'clock-hour-3',
        route: '/inventory/lots', // old lot-reviews
        disabled: false,
        hidden: false,
        children: [],
      },
      {
        name: 'Caducidad',
        icon: null,
        route: '/inventory/expiration',
        disabled: false,
        hidden: true,
        children: [],
      },
      {
        name: 'Conteo de inventario',
        icon: 'zoom-scan',
        route: '/inventory/inventory-count',
        disabled: false,
        hidden: false,
        children: [],
      },
      {
        name: 'Movimientos',
        icon: 'arrow-right-circle',
        route: '/inventory/movements',
        disabled: false,
        hidden: false,
        children: [],
      },
    ],
  },
  {
    name: 'Reportes',
    // icon: '/assets/icons/nav_reports.svg',
    icon: 'chart-pie',
    route: '/reports',
    hidden: false,
    disabled: false,
    children: [
      {
        name: 'General',
        icon: 'chart-pie',
        route: '/reports/general',
        disabled: false,
        hidden: false,
        isNew: false,
        children: [],
      },
      {
        name: 'Más vendidos',
        icon: 'file-analytics',
        route: '/reports/best-sellers',
        disabled: false,
        hidden: false,
        isNew: false,
        children: [],
      },
      {
        name: 'Búsquedas',
        icon: null,
        route: '/reports/search-terms',
        disabled: false,
        hidden: true,
        isNew: false,
        children: [],
      },
      {
        name: 'Descargas',
        icon: null,
        route: '/reports/downloads',
        disabled: false,
        hidden: true,
        children: [],
      },
      // {
      //   name: 'Expiration',
      //   icon: null,
      //   route: '/reports/expiration',
      //   disabled: false,
      //   hidden: true,
      //   children: [],
      // },
    ],
  },
  {
    name: 'Soporte',
    // icon: '/assets/icons/nav_support.svg',
    icon: 'message-2',
    route: '/support',
    disabled: false,
    hidden: true,
    children: [
      {
        name: 'Problemas',
        icon: null,
        route: '/support/issues',
        disabled: false,
        hidden: false,
        isNew: false,
        children: [],
      },
      {
        name: 'Mensajes',
        icon: null,
        route: '/support/messages',
        disabled: false,
        hidden: false,
        children: [],
      },
    ],
  },
  {
    name: 'Configuración',
    // icon: '/assets/icons/nav_settings.svg',
    icon: 'settings',
    route: '/settings',
    disabled: false,
    hidden: false,
    children: [
      {
        name: 'General',
        icon: 'settings',
        route: '/settings/general',
        disabled: false,
        isNew: false,
        hidden: false,
        children: [],
      },
      {
        name: 'Locales',
        icon: 'map-pin',
        route: '/settings/stores',
        disabled: false,
        hidden: false,
        children: [],
      },
      // {
      //   name: 'Métodos de pago',
      //   icon: 'credit-card',
      //   route: '/settings/payment-methods',
      //   disabled: false,
      //   hidden: false,
      //   isNew: false,
      //   children: [],
      // },
      // {
      //   name: 'Métodos de entrega',
      //   icon: null,
      //   route: '/settings/delivery-methods',
      //   disabled: false,
      //   hidden: true,
      //   isNew: false,
      //   children: [],
      // },
      {
        name: 'Series',
        icon: 'invoice',
        route: '/settings/series',
        disabled: false,
        hidden: true,
        children: [],
      },
      // {
      //   name: 'Impuestos',
      //   icon: null,
      //   route: '/settings/taxes',
      //   disabled: false,
      //   hidden: false,
      //   children: [],
      // },
      {
        name: 'Usuarios',
        icon: 'user',
        route: '/settings/users',
        disabled: false,
        hidden: false,
        children: [],
      },
      // {
      //   name: 'Repartidores',
      //   icon: null,
      //   route: '/settings/riders',
      //   disabled: false,
      //   hidden: true,
      //   children: [],
      // },
      // {
      //   name: 'Impresoras',
      //   icon: null,
      //   route: '/settings/printers',
      //   disabled: false,
      //   hidden: false,
      //   children: [],
      // },
      // {
      //   name: 'Sopa de letras',
      //   icon: null,
      //   route: '/settings/letter-soup',
      //   disabled: false,
      //   hidden: true,
      //   children: [],
      // },
      {
        name: 'Planes',
        icon: null,
        route: '/settings/plans',
        disabled: false,
        hidden: true,
        children: [],
      },
    ],
  },
  {
    name: 'Asistente',
    icon: 'circle',
    route: '/assistant',
    disabled: false,
    hidden: true,
    children: [
      {
        name: 'App',
        route: '/assistant/app',
        children: [],
      },
    ],
  },
];
