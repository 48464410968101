import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Tag } from '../models/tag';
import { Response } from '../interfaces/response';
import { TagFilter } from '../filters/tag.filter';
import { OrderBy, SortBy } from '../enums/sorting';

@Injectable({
  providedIn: 'root',
})
export class TagService {
  private readonly index = environment.apiBaseUrl + '/v1/tags';

  constructor(protected api: ApiService) {}

  get(id: string): Observable<Response<Tag | null>> {
    return this.api.get<Response<Tag | null>>(Tag, this.index + '/' + id);
  }

  all(p: TagFilter): Observable<Response<Tag[]>> {
    //const p = new TagFilter({ sort: SortBy.NAME, order: OrderBy.ASC, limit: 500 });
    return this.api.get<Response<Tag[]>>(Tag, this.index + (p?.getQuery() ?? ''));
  }

  create(tag: Tag): Observable<Response<Tag>> {
    return this.api.post<Response<Tag>>(Tag, this.index, tag);
  }

  update(tag: Tag): Observable<Response<Tag>> {
    return this.api.put<Response<Tag>>(Tag, this.index + '/' + tag.id, tag);
  }

  delete(tag: Tag): Observable<Response<Tag>> {
    return this.api.delete<Response<Tag>>(Tag, this.index + '/' + tag.id);
  }
}
