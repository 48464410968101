import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Category } from '../models/category';
import { Response } from '../interfaces/response';
import { AppDatabase } from '../data/app-database';
import { CategoryFilter } from '../filters/category.filter';
import { OrderBy, SortBy } from '../enums/sorting';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  private readonly index = environment.apiBaseUrl + '/v1/categories';

  constructor(protected api: ApiService, private database: AppDatabase) {}

  get(id: string): Observable<Response<Category | null>> {
    return this.api.get<Response<Category | null>>(Category, this.index + '/' + id);
    // return this.api.get<Response<Category | null>>(Category, this.index + '/' + id).pipe(
    //   tap((i) => {
    //     if (i.ok) {
    //       this.database.categories.put(i.data!.entity());
    //     }
    //   })
    // );
  }

  all(): Observable<Response<Category[]>> {
    const p = new CategoryFilter({ sort: SortBy.INDEX, order: OrderBy.ASC, limit: 500 });
    return this.api.get<Response<Category[]>>(Category, this.index + (p?.getQuery() ?? ''));
  }

  create(category: Category): Observable<Response<Category>> {
    return this.api.post<Response<Category>>(Category, this.index, category);
  }

  createMany(categories: Category[]): Observable<Response<Category[]>> {
    return this.api.post<Response<Category[]>>(Category, `${this.index}/bulk`, categories);
  }

  update(category: Category): Observable<Response<Category>> {
    return this.api.put<Response<Category>>(Category, this.index + '/' + category.id, category);
  }

  delete(category: Category): Observable<Response<Category>> {
    return this.api.delete<Response<Category>>(Category, this.index + '/' + category.id);
  }

  sort(categoryIds: string[]): Observable<Response<Category>> {
    return this.api.post<Response<Category>>(Category, this.index + '/sort', categoryIds);
  }
}
