import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Subscription } from '../models/subscription';
import { Response } from '../interfaces/response';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  private readonly index = environment.apiBaseUrl + '/v1/subscriptions';

  constructor(protected api: ApiService) {}

  get(id: string): Observable<Response<Subscription | null>> {
    return this.api.get<Response<Subscription | null>>(Subscription, this.index + '/' + id);
  }

  update(subscription: Subscription): Observable<Response<Subscription>> {
    return this.api.put<Response<Subscription>>(Subscription, this.index + '/' + subscription.id, subscription);
  }
}
