import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Register } from '../models/register';
import { Response } from '../interfaces/response';
import { RegisterFilter } from '../filters/register.filter';
import { CashMovementType } from '../enums/cash-movement-type';
import { CashMovement } from '../models/register-closure';

@Injectable({
  providedIn: 'root',
})
export class RegisterService {
  private readonly index = environment.apiBaseUrl + '/v1/registers';

  constructor(protected api: ApiService) {}

  open(registerId: string, body: { user_id: string; amount: number; note: string }): Observable<Response<Register>> {
    return this.api.post<Response<Register>>(Register, `${this.index}/${registerId}/open`, body);
  }

  close(
    registerId: string,
    body: { user_id: string; payments: { id: string; counted: number }[]; note: string }
  ): Observable<Response<Register>> {
    return this.api.post<Response<Register>>(Register, `${this.index}/${registerId}/close`, body);
  }

  /**
   * List cash movements
   * @param registerId register id
   * @returns response with cash movements
   */
  listCashMovements(registerId: string): Observable<Response<CashMovement[]>> {
    return this.api.get<Response<CashMovement[]>>(Register, `${this.index}/${registerId}/cash_movements`);
  }

  createCashMovement(
    registerId: string,
    body: { user_id: string; amount: number; type: CashMovementType; note: string }
  ): Observable<Response<CashMovement>> {
    return this.api.post<Response<CashMovement>>(CashMovement, `${this.index}/${registerId}/cash_movements`, body);
  }

  get(id: string): Observable<Response<Register | null>> {
    return this.api.get<Response<Register | null>>(Register, this.index + '/' + id);
  }

  list(p?: Partial<RegisterFilter>): Observable<Response<Register[]>> {
    return this.api.get<Response<Register[]>>(Register, this.index + new RegisterFilter(p)?.getQuery());
  }

  all(p: RegisterFilter | null = null): Observable<Response<Register[]>> {
    return this.api.get<Response<Register[]>>(Register, this.index + (p ? p!.getQuery() : ''));
  }

  create(register: Register): Observable<Response<Register>> {
    return this.api.post<Response<Register>>(Register, this.index, register);
  }

  update(register: Register): Observable<Response<Register>> {
    return this.api.put<Response<Register>>(Register, this.index + '/' + register.id, register);
  }

  delete(register: Register): Observable<Response<Register>> {
    return this.api.delete<Response<Register>>(Register, this.index + '/' + register.id);
  }
}
