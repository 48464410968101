import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Discount } from '../models/discount';
import { Response } from '../interfaces/response';
import { DiscountFilter } from '../filters/discount-filter';
import { Product } from '../models/product';

@Injectable({
  providedIn: 'root',
})
export class DiscountService {
  private readonly index = environment.apiBaseUrl + '/v1/discounts';

  constructor(protected api: ApiService) {}

  get(id: string): Observable<Response<Discount | null>> {
    return this.api.get<Response<Discount | null>>(Discount, this.index + '/' + id);
  }

  all(p: DiscountFilter | null = null): Observable<Response<Discount[]>> {
    return this.api.get<Response<Discount[]>>(Discount, this.index + (p ? p!.getQuery() : ''));
  }

  create(discount: Discount): Observable<Response<Discount>> {
    return this.api.post<Response<Discount>>(Discount, this.index, discount);
  }

  update(discount: Discount): Observable<Response<Discount>> {
    return this.api.put<Response<Discount>>(Discount, this.index + '/' + discount.id, discount);
  }

  delete(discount: Discount): Observable<Response<Discount>> {
    return this.api.delete<Response<Discount>>(Discount, this.index + '/' + discount.id);
  }

  addProducts(discountId: string, productIds: string[]): Observable<Response<Product[]>> {
    return this.api.post<Response<Product[]>>(Product, `${this.index}/${discountId}/products`, productIds);
  }

  removeProducts(discountId: string, productIds: string[]): Observable<Response<Product[]>> {
    return this.api.post<Response<Product[]>>(Product, `${this.index}/${discountId}/remove_products`, productIds);
  }

  products(discountId: string): Observable<Response<Product[]>> {
    return this.api.get<Response<Product[]>>(Product, `${this.index}/${discountId}/products`);
  }
}
