import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { UserConfig } from '../services/user-config.service';

@Injectable()
export class DataInterceptor implements HttpInterceptor {
  constructor(private userConfig: UserConfig) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = request.headers
      .set('Cache-Control', 'no-cache, no-store, must-revalidate')
      //.set('App-Id', 'store')
      .set('App-Client', 'web');

    if (
      request.url.startsWith(environment.apiBaseUrl) ||
      request.url.startsWith(environment.order) ||
      request.url.startsWith(environment.aiServicesURL)
    ) {
      headers = headers
        .set('Content-Type', 'application/json')
        //.set('Business-Id', this.userConfig.currentBusinessId)
        .set('Store-Id', this.userConfig.currentLocalId);
    }

    const dupReq = request.clone({ headers });

    return next.handle(dupReq);
  }
}
