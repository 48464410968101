import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { GiftCardGroup } from '../models/gift-card-group';
import { Response } from '../interfaces/response';
import { GiftCardGroupFilter } from '../filters/gift-card-group.filter';

@Injectable({
  providedIn: 'root',
})
export class GiftCardGroupService {
  private readonly index = environment.apiBaseUrl + '/v1/gift_card_groups';

  constructor(protected api: ApiService) {}

  get(id: string): Observable<Response<GiftCardGroup | null>> {
    return this.api.get<Response<GiftCardGroup | null>>(GiftCardGroup, this.index + '/' + id);
  }

  all(p: GiftCardGroupFilter | null = null): Observable<Response<GiftCardGroup[]>> {
    return this.api.get<Response<GiftCardGroup[]>>(GiftCardGroup, this.index + p?.getQuery() ?? '');
  }

  create(giftCardGroup: GiftCardGroup): Observable<Response<GiftCardGroup>> {
    return this.api.post<Response<GiftCardGroup>>(GiftCardGroup, this.index, giftCardGroup);
  }

  update(giftCardGroup: GiftCardGroup): Observable<Response<GiftCardGroup>> {
    return this.api.put<Response<GiftCardGroup>>(GiftCardGroup, this.index + '/' + giftCardGroup.id, giftCardGroup);
  }

  delete(giftCardGroup: GiftCardGroup): Observable<Response<GiftCardGroup>> {
    return this.api.delete<Response<GiftCardGroup>>(GiftCardGroup, this.index + '/' + giftCardGroup.id);
  }
}
