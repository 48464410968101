import { Tax } from './tax';

export enum PriceBookType {
  STANDARD = 'standard',
  CUSTOM = 'custom',
}

export class PriceBook {
  id: string;
  name: string;
  description: string;
  type: PriceBookType;
  storeIds: string[];
  updatedAt: Date;
  createdAt: Date;

  constructor(init?: Partial<PriceBook>) {
    Object.assign(this, init);
  }

  public toJSON(): any {
    return {};
  }
}

export class Price {
  id: string;
  storeId: string;
  storeName: string;
  productId: string;
  supplyPrice: number;
  tax: Tax;
  price: number; // Excluding or Including tax
  priceIncludingTax: number;
  priceExcludingTax: number;
  supplierId: string;
  markup: number;
  updatedAt: Date;
  createdAt: Date;

  // supplier?: ProductSupplier;

  constructor(init?: Partial<Price>) {
    Object.assign(this, init);
    this.tax = init?.tax ? new Tax(init.tax) : new Tax();
    // this.supplier = init?.supplier ? new ProductSupplier(init.supplier) : undefined;
  }

  public toJSON(): any {
    return {
      id: this.id,
      store_id: this.storeId,
      item_id: this.productId,
      // supply_price: this.supplyPrice,
      // markup: this.markup,
      tax_id: this.tax?.id,
      price: this.price,
      price_including_tax: this.priceIncludingTax,
      price_excluding_tax: this.priceExcludingTax,
    };
  }
}
