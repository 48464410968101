import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Consignment } from '../models/consignment';
import { Response } from '../interfaces/response';
import { ConsignmentFilter } from '../interfaces/consignment-filter';
import { ConsignmentStatus } from '../enums/consignment-status';

@Injectable({
  providedIn: 'root',
})
export class ConsignmentService {
  private readonly index = environment.apiBaseUrl + '/v1/consignments';

  constructor(protected api: ApiService) {}

  get(id: string): Observable<Response<Consignment | null>> {
    return this.api.get<Response<Consignment | null>>(Consignment, this.index + '/' + id);
  }

  all(p: ConsignmentFilter | null = null): Observable<Response<Consignment[]>> {
    let q = '';
    q += p?.search ? '&search=' + p.search : '';
    q += p?.storeId ? '&store_id=' + p.storeId : '';
    q += p?.supplierId ? '&supplier_id=' + p.supplierId : '';
    q += p?.type ? '&type=' + p.type : '';
    q += p?.status ? '&status=' + p.status : '';
    q = q ? '?' + q.substring(1) : '';
    return this.api.get<Response<Consignment[]>>(Consignment, this.index + q);
  }

  create(model: Consignment): Observable<Response<Consignment>> {
    return this.api.post<Response<Consignment>>(Consignment, this.index, model);
  }

  update(model: Consignment): Observable<Response<Consignment>> {
    return this.api.put<Response<Consignment>>(Consignment, this.index + '/' + model.id, model);
  }

  open(consignmentId: string): Observable<Response<Consignment>> {
    return this.api.post<Response<Consignment>>(Consignment, `${this.index}/${consignmentId}/open`, null);
  }

  send(consignmentId: string): Observable<Response<Consignment>> {
    return this.api.post<Response<Consignment>>(Consignment, `${this.index}/${consignmentId}/send`, null);
  }

  dispatch(consignmentId: string): Observable<Response<Consignment>> {
    return this.api.post<Response<Consignment>>(Consignment, `${this.index}/${consignmentId}/dispatch`, null);
  }

  cancel(consignmentId: string): Observable<Response<Consignment>> {
    return this.api.post<Response<Consignment>>(Consignment, `${this.index}/${consignmentId}/cancel`, null);
  }

  markAs(consignmentId: string, status: ConsignmentStatus): Observable<Response<Consignment>> {
    return this.api.put<Response<Consignment>>(
      Consignment,
      this.index + '/' + consignmentId,
      new Consignment({ status: status })
    );
  }

  receive(consignmentId: string): Observable<Response<Consignment>> {
    return this.api.post<Response<Consignment>>(Consignment, `${this.index}/${consignmentId}/receive`, null);
  }

  return(consignmentId: string): Observable<Response<Consignment>> {
    return this.api.post<Response<Consignment>>(Consignment, `${this.index}/${consignmentId}/return`, null);
  }
}
