import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { PriceHistory } from '../models/price-history';
import { Response } from '../interfaces/response';
import { PriceHistoryFilter } from '../filters/price-history.filter';

@Injectable({
  providedIn: 'root',
})
export class PriceHistoryService {
  private readonly index = environment.apiBaseUrl + '/v1/price_histories';

  constructor(protected api: ApiService) {}

  get(id: string): Observable<Response<PriceHistory | null>> {
    return this.api.get<Response<PriceHistory | null>>(PriceHistory, this.index + '/' + id);
  }

  all(p: PriceHistoryFilter): Observable<Response<PriceHistory[]>> {
    return this.api.get<Response<PriceHistory[]>>(PriceHistory, this.index + (p?.getQuery() ?? ''));
  }

  // create(priceHistory: PriceHistory): Observable<Response<PriceHistory>> {
  //   return this.api.post<Response<PriceHistory>>(PriceHistory, this.index, priceHistory);
  // }

  update(priceHistory: PriceHistory): Observable<Response<PriceHistory>> {
    return this.api.put<Response<PriceHistory>>(PriceHistory, this.index + '/' + priceHistory.id, priceHistory);
  }

  // delete(priceHistory: PriceHistory): Observable<Response<PriceHistory>> {
  //   return this.api.delete<Response<PriceHistory>>(PriceHistory, this.index + '/' + priceHistory.id);
  // }
}
