import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { LetterSoup } from '../models/letter-soup';
import { Response } from '../interfaces/response';

@Injectable({
  providedIn: 'root',
})
export class LetterSoupService {
  private readonly index = environment.apiBaseUrl + '/v1/letter_soups';

  constructor(protected api: ApiService) {}

  get(id: string): Observable<Response<LetterSoup | null>> {
    ///return of(responseSuccess<LetterSoup>(itemsLetterSoup._first()));
    return this.api.get<Response<LetterSoup | null>>(LetterSoup, this.index + '/' + id);
  }

  all(): Observable<Response<LetterSoup[]>> {
    //return of(responseSuccess<LetterSoup[]>(itemsLetterSoup));
    return this.api.get<Response<LetterSoup[]>>(LetterSoup, this.index);
  }

  create(letterSoup: LetterSoup): Observable<Response<LetterSoup>> {
    return this.api.post<Response<LetterSoup>>(LetterSoup, this.index, letterSoup);
  }

  update(letterSoup: LetterSoup): Observable<Response<LetterSoup>> {
    return this.api.put<Response<LetterSoup>>(LetterSoup, this.index + '/' + letterSoup.id, letterSoup);
  }

  delete(letterSoup: LetterSoup): Observable<Response<LetterSoup>> {
    return this.api.delete<Response<LetterSoup>>(LetterSoup, this.index + '/' + letterSoup.id);
  }
}
