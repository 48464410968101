import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { GiftCard } from '../models/gift-card';
import { Response } from '../interfaces/response';
import { GiftCardFilter } from '../filters/gift-card.filter';

@Injectable({
  providedIn: 'root',
})
export class GiftCardService {
  private readonly index = environment.apiBaseUrl + '/v1/gift_cards';

  constructor(protected api: ApiService) {}

  notify(giftCardId: string): Observable<Response<GiftCard>> {
    return this.api.post<Response<GiftCard>>(GiftCard, `${this.index}/${giftCardId}/notify`, null);
  }

  get(id: string): Observable<Response<GiftCard | null>> {
    return this.api.get<Response<GiftCard | null>>(GiftCard, this.index + '/' + id);
  }

  all(q: GiftCardFilter | null = null): Observable<Response<GiftCard[]>> {
    return this.api.get<Response<GiftCard[]>>(GiftCard, this.index + (q?.getQuery() ?? ''));
  }

  create(giftCard: GiftCard): Observable<Response<GiftCard>> {
    return this.api.post<Response<GiftCard>>(GiftCard, this.index, giftCard);
  }

  update(giftCard: GiftCard): Observable<Response<GiftCard>> {
    return this.api.put<Response<GiftCard>>(GiftCard, this.index + '/' + giftCard.id, giftCard);
  }

  delete(giftCard: GiftCard): Observable<Response<GiftCard>> {
    return this.api.delete<Response<GiftCard>>(GiftCard, this.index + '/' + giftCard.id);
  }
}
