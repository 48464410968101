import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Sale } from '../models/sale';
import { Response } from '../interfaces/response';
import { SaleFilter } from '../filters/sale.filter';
import { SaleCreateRequest } from './requests/sale-create.request';
import { SalePrintData } from '../models/print-sale-data';
import { HttpClient } from '@angular/common/http';

const { v4: uuidv4 } = require('uuid');

@Injectable({
  providedIn: 'root',
})
export class SaleService {
  private readonly index = environment.apiBaseUrl + '/v1/orders';

  constructor(protected api: ApiService, protected http: HttpClient) {}

  get(id: string): Observable<Response<Sale | null>> {
    return this.api.get<Response<Sale | null>>(Sale, this.index + '/' + id);
  }

  data(id: string): Observable<Response<SalePrintData | null>> {
    return this.http.get<Response<SalePrintData | null>>(`${this.index}/${id}/data`);
  }

  all(f: Partial<SaleFilter>): Observable<Response<Sale[]>> {
    return this.api.get<Response<Sale[]>>(Sale, this.index + (f ? new SaleFilter(f)?.getQuery() : ''));
  }

  create(order: SaleCreateRequest): Observable<Response<Sale>> {
    const headers = { headers: { 'Idempotency-Key': uuidv4() } };
    return this.api.post<Response<Sale>>(Sale, `${this.index}`, order, headers);
  }

  update(order: Sale): Observable<Response<Sale>> {
    return this.api.put<Response<Sale>>(Sale, this.index + '/' + order.id, order);
  }

  delete(order: Sale): Observable<Response<Sale>> {
    return this.api.delete<Response<Sale>>(Sale, this.index + '/' + order.id);
  }

  deliver(orderId: string): Observable<Response<Sale>> {
    return this.api.post<Response<Sale>>(Sale, `${this.index}/${orderId}/deliver`, null);
  }

  accept(orderId: string): Observable<Response<Sale>> {
    return this.api.post<Response<Sale>>(Sale, `${this.index}/${orderId}/accept`, null);
  }

  cancel(orderId: string, observation: string | undefined = undefined): Observable<Response<Sale>> {
    const body = { observation: observation };
    return this.api.post<Response<Sale>>(Sale, `${this.index}/${orderId}/cancel`, body);
  }
}
