import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Response } from '../interfaces/response';
import { SignIn } from '../models/sign-in';
import { AuthData } from '../models/auth_data';
import { UserConfig } from '../services/user-config.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthApi {
  private readonly authDataUrl = environment.authServerUrl + '/v1/data';
  private readonly authSingInUrl = environment.authServerUrl + '/v1/sign_in';

  constructor(protected api: ApiService, private config: UserConfig) {}

  data(): Observable<Response<AuthData>> {
    return this.api.post<Response<AuthData>>(AuthData, this.authDataUrl, null);
  }

  signIn(token: string): Observable<Response<SignIn>> {
    return this.api.post<Response<SignIn>>(SignIn, this.authSingInUrl, { access_token: token });
  }
}
